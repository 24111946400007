<template>
  <div class="hero-pattern min-h-screen flex flex-col justify-between items-center">
    <parcelex-business-header />
    <div class="flex-shrink-0 p-6 lg:p-0 w-full md:max-w-md">
      <div class="p-6 lg:p-9 lg:pb-8 bg-white rounded-2xl mb-6 shadow-lg">
          <header class="items-center flex-col flex">
            <div class="flex-shrink-0 mb-2">
              <img class="w-btn mr-3" src="brand-icon-mail-closed.svg" />
            </div>
            <h1 class="font-bold text-lg mb-6">Verifique seu email</h1>
            <p class="text-gray-500 mb-3 text-center">
              Enviamos um email com instruções para recuperação de senha para:
            </p>
          </header>
          <p class="text-gray-500 font-semibold text-center">
            {{ username }}
          </p>
      </div>
    </div>
    <footer class="text-center text-primary-500 pt-4 pb-6">
      &copy; Parcelex - {{ actualDate }}
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ParcelexBusinessHeader from '../../../components/common/ParcelexBusinessHeader.vue';
import isMobile from '../../../lib/isMobile';

export default {
  components: { ParcelexBusinessHeader },
  name: 'verify-email',
  metaInfo: {
    title: 'Para Negócios',
  },
  data: () => ({
    mobile: false,
  }),
  computed: {
    ...mapState('auth', ['username']),
    actualDate() {
      return new Date().getFullYear();
    },
  },
  mounted() {
    this.mobile = isMobile();
  },
};
</script>
